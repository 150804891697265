export function initOverlay() {
  const overlay = document.querySelector(".overlay");
  const closeButton = document.querySelector(".overlay-close");

  if (closeButton == null) {
    return;
  }

  closeButton.addEventListener("click", function () {
    overlay.classList.remove("open");
    window.location.hash = "";
  });
}
