import { createMap } from "./Modules/map";
import { createSlider } from "./Modules/slider";
import { initOverlay } from "./Modules/overlay";
import { initLayer } from "./Modules/layer";

document.addEventListener("DOMContentLoaded", function (event) {
  if (document.getElementById("map") == null) {
    return;
  }
  createMap();
  createSlider();
  initOverlay();
  initLayer();
});
