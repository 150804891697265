import maplibregl from "maplibre-gl";
import useFetchJson from "./useFetchJson";
let popup = null;

export function createMap() {
  const mapEl = document.getElementById("map");

  if (mapEl == null) {
    return;
  }

  const center = mapEl.dataset.center ? JSON.parse(mapEl.dataset.center) : [9.209490, 48.807834];
  const zoom = mapEl.dataset.zoom ?? 9;
  const maxBounds = mapEl.dataset.bounds ? JSON.parse(mapEl.dataset.bounds) : [[-170.0, -85.0], [189.999, 85.0]];
  const mapTilerFlat = mapEl.dataset.basicMap ?? "https://api.maptiler.com/maps/5d11f0a6-e6e4-4db4-b153-de66a59d97ab/style.json?key=03o8bIViwVDNSt2uOphH";
  const mapTilerSatellite = mapEl.dataset.satelliteMap ?? "https://api.maptiler.com/maps/18f339da-bfac-49dd-a595-4ae46dc4894e/style.json?key=03o8bIViwVDNSt2uOphH";

  const map = new maplibregl.Map({
    container: mapEl,
    style: mapTilerFlat,
    zoom: zoom,
    maxZoom: 13,
    center: center,
    maxBounds: maxBounds,
  });

  const nav = new maplibregl.NavigationControl();

  map.on("load", () => {
    map.addControl(nav, "bottom-left");

		const mapSwitch = document.getElementById("mapswitch");

    /* map style */
    mapSwitch.addEventListener("click", changeMap);
    mapSwitch.addEventListener("touchstart", changeMap);

    function changeMap() {
      if (mapSwitch.classList.contains("map-flat")) {
        map.setStyle(mapTilerFlat);
        mapSwitch.classList.remove("map-flat");
        mapSwitch.classList.add("map-satellite");
      } else if (mapSwitch.classList.contains("map-satellite")) {
        map.setStyle(mapTilerSatellite);
        mapSwitch.classList.remove("map-satellite");
        mapSwitch.classList.add("map-flat");
      }
    }

    addRoute();
    addMarker();
  });

  map.on("styledata", function () {
    if (!map.getSource("route") && !map.getLayer("route")) {
      addRoute();
    }
  });

  /* train track line */
  function addRoute() {
    const urlTrack = mapEl.dataset.geojsontrack;

    useFetchJson(urlTrack)
      .then((data) => {
        if (!map.getSource("route")) {
          map.addSource("route", {
            type: "geojson",
            data,
          });
        }

        if (!map.getLayer("route")) {
          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#C50014",
              "line-width": 4,
            },
          });

          if (!document.querySelector('input[name="route"]:checked')) {
            map.setLayoutProperty("route", "visibility", "none");
          }
        }
      });
  }

  /* station points */
  function addMarker() {
    const urlPoints = mapEl.dataset.stations;

    useFetchJson(urlPoints)
      .then((data) => {
        map.addSource("stations", {
          type: "geojson",
          data,
        });

        // add markers to map
        data.features.forEach(function (marker, key) {
          // create a DOM element for the marker
          const el = document.createElement("div");
          el.id = "marker-" + key
          el.className = "marker";
          el.dataset.type = marker.properties.type;
          el.style.backgroundImage = "url(" + marker.properties.iconSrc + ")";
          el.style.width = "30px";
          el.style.height = "30px";

          document.querySelector('#layer-menu .form[data-layer="' + marker.properties.type + '"]').classList.add('visible');

          el.addEventListener("click", function () {
            window.location.hash = marker.properties.id;
            document.querySelector(".overlay").classList.add("open");
          });

          el.addEventListener("touchstart", function () {
            window.location.hash = marker.properties.id;
            document.querySelector(".overlay").classList.add("open");
          });

          el.addEventListener("mouseover", function (e) {
            map.getCanvas().style.cursor = "pointer";
            marker.DOMid = el.id;
            enterMarker(marker, map);
          });

          el.addEventListener("mouseout", function (e) {
            map.getCanvas().style.cursor = "";

            if (popup && popup.isOpen()) popup.remove();
          });

          // add marker to map
          new maplibregl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
        });
      });

  }

  const checkboxes = document.querySelectorAll("#layer-menu input");
  const store = {};

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    store[name] = checked;

		map.setLayoutProperty("route", "visibility", store.route ? "visible" : "none");

		Object.keys(store).forEach((name) => {
			if (name !== 'route') {
				if (!store[name]) {
					mapEl.classList.add(`hide-${name}`);
				} else {
					mapEl.classList.remove(`hide-${name}`);
				}
			}
		});
  }

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", handleCheckboxChange);
    const { name, checked } = checkbox;
    store[name] = checked;
  });
}

function enterMarker(marker, map) {
	let image = '';

  if (marker.properties.imageSrc) {
    image = `<div class="image-container"><img src="${marker.properties.imageSrc}" /></div>`;
}

  popup = new maplibregl.Popup({
    offset: 15,
    className: "map-popup", //counter mitgeben .. .schauen geht das auch bei marker?
    closeButton: false,
  })
  .setLngLat(marker.geometry.coordinates) // muss unten halt nochmal gemacht werden egal
  .setHTML(
    `
      ${image}
      <h4>${marker.properties.name}</h4>
      ${marker.properties.text ? `<p>${marker.properties.text}</p>` : ''}
      `
  );

    //threshold
  const threshold = 20;

  //map bounding
  const mapRect = document.querySelector('#map').getBoundingClientRect();
  const headerHeight = mapRect.top;

  //erste berechnung um zu prüfen ob zu groß für normales Einblenden
  const markerBounding = document.querySelector("#" + marker.DOMid).getBoundingClientRect();
  const markerDistanceTop = markerBounding.y + (markerBounding.height/2) - headerHeight //abzüglich des headers der 90 beträgt
 

  //hinzufügen
  popup.addTo(map);
  popup._update();

  //ausblenden  
  popup._container.style.opacity = 0;

  //calclulation:
  let popupRect = popup._container.getBoundingClientRect(); //vlt selbe dann kann id echt weg

  //seitlich ausfahren wenn bedingungen passen
  if((popupRect.y - headerHeight) + popupRect.height >= mapRect.height){

    //anpassung des Popups
    popup._tip.style.position = "absolute";

    //part für nach rechts ausrichten:
    popup.options.anchor = 'left';
    popup._tip.style.left = "-10px";
    
    //zentriere den Popup vertikal
    const abstandMarkerMitte = (mapRect.height/2) - markerDistanceTop;
    popup._container.style.transform = "none !important";
    popup._container.style.top = abstandMarkerMitte + "px";
    popup._container.style.left = "15px"; // beim anderen dann right
    popup._update();

    popupRect = popup._container.getBoundingClientRect(); //vlt selbe dann kann id echt weg
    
    //Anpassung des Popups (Tip)
    if(popupRect.x + popupRect.width + threshold >= mapRect.width){
      //part für nach links ausrichten:
      popup.options.anchor = 'right';
      popup._container.style.left = "-15px";
      popup._tip.style.left = "auto";
      popup._tip.style.right = "-10px";
      popup._update();
    }

    const distanceToOuterContainer = popupRect.y - headerHeight; //vlt map offset nach ausen auslesen ? .. wäre nice
    const newTipPosition = markerDistanceTop - distanceToOuterContainer
    popup._tip.style.top = newTipPosition + "px";
    
    //check ob marker überhalb liegt:
    if( markerDistanceTop < (popupRect.y - headerHeight)){
      popup._tip.style.top = 10 + "px";
      popup._container.style.top = popupRect.height/2 - 20 + "px";
      popup._update();
    }

    //check ob marker unterhalb liegt:
    if( (markerBounding.y + markerBounding.height)> (popupRect.y + popupRect.height)){
      popup._container.style.top = "unset";
      popup._container.style.top =  `${(-popupRect.height/2)+10}px`;
      popup._tip.style.top = "unset";
      popup._tip.style.top = `${popupRect.height-25}px`;
      popup._update();
    }
  }
  
  //einblenden
  popup._update();
  popup._container.style.opacity = 1;
}