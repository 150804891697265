import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

/**
 * news101-s-h
 * Displays cards in a slider
 */

class News101_Slider_Homepage {
  /**
   * constructor for News101_Slider_Homepage
   *
   * @param   {HTMLElement}  wrapperElement Outer Element of Component
   *
   * @return  {ClassInstance}
   */
  constructor(wrapperElement) {
    this.s = {
      slider: ".js-news101-s-h__slider",
      slide: ".js-news101-s-h__slide",
      pagination: ".js-news101-s-h__pagination",
      prev: ".js-news101-s-h__prev",
      next: ".js-news101-s-h__next",
      navigation: ".js-news101-s-h__navigation",
    };
    this.e = {
      wrapper: wrapperElement,
      slider: wrapperElement.querySelector(this.s.slider),
      slides: wrapperElement.querySelectorAll(this.s.slide),
      pagination: wrapperElement.querySelector(this.s.pagination),
      prev: wrapperElement.querySelector(this.s.prev),
      next: wrapperElement.querySelector(this.s.next),
      navigation: wrapperElement.querySelector(this.s.navigation),
    };

    this.options = {
      navigationVisibleClass: "news101-s-h__navigation--visible",
    };

    this.swiper;

    if (this.e.wrapper && this.e.slider) {
      this.init();
    }

    return this;
  }

  init() {
    this.initSwiper();
    this.initBreakpoints();
  }

  /**
   * Init the swiper instance
   */
  initSwiper() {
    this.swiper = new Swiper(this.e.slider, {
      slidesPerView: 1.2,
      spaceBetween: 24,
      centeredSlides: true,
      centerInsufficientSlides: false,
      pagination: {
        clickable: true,
        el: this.e.pagination,
        type: "bullets", //hier zu fraction changen wenn desktop "fraction"
        enabled: true,
      },
      navigation: {
        nextEl: this.e.next,
        prevEl: this.e.prev,
        el: this.e.navigation,
      },

      breakpoints: {
        1: {
          slidesPerView: 1.4,
          centerInsufficientSlides: true,
          centeredSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        400: {
          slidesPerView: 1.6,
          centeredSlides: true,
          centerInsufficientSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        600: {
          slidesPerView: 2,
          centeredSlides: true,
          centerInsufficientSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        800: {
          slidesPerView: 1.5,
          centeredSlides: false,
          centerInsufficientSlides: false,

          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: false,
          },
        },
        1024: {
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: false,
          },
          slidesPerView: 2.2,
          centeredSlides: false,
          centerInsufficientSlides: false,
        },
        1400: {
          slidesPerView: 2.2,
          centeredSlides: false,
          centerInsufficientSlides: false,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: false,
          },
        },
        1600: {
          slidesPerView: 2.5,
          centeredSlides: false,
          centerInsufficientSlides: false,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: false,
          },
        },
      },
      on: {
        afterInit: (swiperInstance) => {
          if (!this.e.navigation) {
            return;
          }

          if (swiperInstance.slides.length >= 2) {
            this.e.navigation.classList.add(this.options.navigationVisibleClass);

            if (swiperInstance.currentBreakpoint < 800) {
              this.e.navigation.classList.add("hidden");

              this.e.wrapper.closest("section").classList.add("ml0");
              this.e.wrapper.closest("section").classList.remove("mt-10");

              /* change to collapsed  */
              const items = this.e.wrapper.querySelectorAll(".news101-item");
              console.log(items);
              items.forEach((el) => {
                el.classList.add("news101-item--collapsed");
              });
            }
          }
        },
      },
    });
  }

  initBreakpoints() {
    /* ging nicht anders */
    const _navigation = this.e.navigation;
    const _slider = this.e.slider;
    const _wrapper = this.e.wrapper;

    this.swiper.on("breakpoint", function () {
      const targetSection = _slider.closest("section");
      if (this.currentBreakpoint >= 800) {
        /* remove all collapsed classes from slides */
        const collapsedItems = _wrapper.querySelectorAll(".news101-item--collapsed");
        console.log(collapsedItems);
        collapsedItems.forEach((el) => {
          el.classList.remove("news101-item--collapsed");
        });

        /* swiper changes */
        if (this.pagination?.el?.classList) {
          this.pagination.el.classList.add("hidden");
        }
        if (_navigation?.classList) {
          _navigation.classList.remove("hidden");
        }

        targetSection.classList.remove("ml0");
        targetSection.classList.add("mt-10");
      } else {
        if (this.pagination?.el?.classList) {
          this.pagination.el.classList.remove("hidden");
        }

        if (_navigation?.classList) {
          _navigation.classList.add("hidden");
        }

        const items = _wrapper.querySelectorAll(".news101-item");
        console.log(items);
        items.forEach((el) => {
          el.classList.add("news101-item--collapsed");
        });

        targetSection.classList.add("ml0");
        targetSection.classList.remove("mt-10");
      }
      this.update();
    });
  }
}

const newsElements = document.querySelectorAll(".js-news101-s-h");
newsElements.forEach(function (element) {
  new News101_Slider_Homepage(element);
});
