// import Swiper JS
import Swiper, { Navigation, HashNavigation, EffectFade } from "swiper";
import useFetchJson from "./useFetchJson";

Swiper.use([Navigation, HashNavigation, EffectFade]);

let mySwiper = null;

const scrollClassHelper = () => {
  const currentSlide = mySwiper.slides[mySwiper.activeIndex];

  const currentWrapper = currentSlide.querySelector(".slide-content-wrapper");
  const currentContent = currentSlide.querySelector(".slide-content");
  

  if(currentWrapper.clientHeight < currentContent.clientHeight){
    currentContent.classList.add('scrollable')
  }
  else currentContent.classList.remove('scrollable')
} 


export function createSlider() {
  const mapEl = document.getElementById("map");
  if (mapEl == null) {
    return;
  }
  useFetchJson(mapEl.dataset.stations).then((data) => {
    // add markers as slide
    data.features.forEach(function (marker, index) {
      // create a DOM element for the slide
      const el = document.createElement("div");
      el.className = "swiper-slide";
      el.dataset.hash = marker.properties.id;

      let image = "";
      let prevName = "";
      let nextName = "";

      if (marker.properties.imageSrc) {
        image = `<div class="image-container"><img src="${marker.properties.imageSrc}" /></div>`;
      }

      if (index > 0) {
        prevName = data.features[index - 1].properties.name;
      } else if (index === 0) {
        prevName = data.features[data.features.length - 1].properties.name;
      }

      if (index < data.features.length - 1) {
        nextName = data.features[index + 1].properties.name;
      } else if (index === data.features.length - 1) {
        nextName = data.features[0].properties.name;
      }

      const slideContent = `
          <div class="slide-container">
            <div class="slide-before">
              <div class="slide-title">${prevName}</div>
            </div>
            <div class="slide-content-wrapper">
              <div class="slide-content">
                ${image}
                <div class="slide-text">
							  ${marker.properties.preHeadline ? `<div class="pre-headline">${marker.properties.preHeadline}</div>` : ""}
							  <h4>${marker.properties.name}</h4>
                  ${marker.properties.text ? `<p>${marker.properties.text}</p>` : ""}
                </div>
              </div>
            </div>
            <div class="slide-next">
              <div class="slide-title">${nextName}</div>
            </div>
          </div>
        `;

      el.innerHTML = slideContent;
      document.querySelector(".swiper-wrapper").appendChild(el);
    });

    //mySwiper =  instanzzieren wenn helper verwendet werden soll
    mySwiper = new Swiper(".swiper", {
      effect: "fade",
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      hashNavigation: {
        watchState: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      keyboard: {
        enabled: true,
      },
    });

    //call helper
    mySwiper.on('slideChange', function () {
        scrollClassHelper();
      });
  });

      window.addEventListener("resize", scrollClassHelper)
}
