import { $All } from "Packages/Bahn/DB.Neos.Base/Resources/Private/Assets/Scripts/Modules/Helper";

//
// Accordion
//

var hash = window.location.hash;

var accordion = $All(".js-accordion");
var accordionButton = $All(".js-accordion__button");
//var accordionCollapse = $All(".js-accordion__collapse");

// check if hash has changed
function locationHashChanged() {
  const el = document.getElementById(location.hash.substring(1));
  if (el && el.classList.contains("js-accordion__button")) {
    toggleAccordionItem(el);
  }
}

function setTimelineImages(parent) {
  if (parent.getElementsByClassName("js-timeline__media")[0] != null && window.breakpoint.value >= 800) {
    var timelineItem = parent.parentNode;
    var imagewrapper = timelineItem.getElementsByClassName("js-timeline__media")[0];
    var image = imagewrapper.getElementsByClassName("image")[0];
    var buttonHeight = timelineItem.getElementsByClassName("timeline__title")[0].clientHeight;
    var imagewrapperHeight = imagewrapper.clientHeight;
    timelineItem.style.minHeight = imagewrapperHeight + buttonHeight + "px";
    imagewrapper.style.top = buttonHeight + "px";
    imagewrapper.style.height = imagewrapperHeight + "px";
  }
} // Set Timeline Lines on Window Resize

function toggleAccordionItem(el) {
  var isopen = el.getAttribute("aria-expanded");
  var parent = el.parentNode.parentNode;
  var title = parent.querySelector(".accordion__title, .timeline__title");
  var content = parent.querySelector(".accordion__collapse, .timeline__collapse");

  if (isopen == "false") {
    el.setAttribute("aria-expanded", true);
    parent.classList.add("is--open");
    title.classList.add("is--open");
    el.blur();

    content.style.height = "auto";
    var height = content.clientHeight + "px";
    content.style.height = "0px";

    setTimeout(function () {
      content.style.height = height;
      setTimelineImages(parent);

      content.addEventListener(
        "transitionend",
        function () {
          if (parent.querySelector(".js-timeline__media") !== null) {
            parent.querySelector(".js-timeline__media .image").style.opacity = "1";
          }
        },
        {
          once: true,
        }
      );
    }, 0);
  } else if (parent.querySelector(".js-timeline__media") !== null) {
    parent.querySelector(".js-timeline__media .image").style.opacity = "0";

    content.style.height = "0px";
    parent.parentNode.style.minHeight = "0";

    setTimeout(function () {
      el.setAttribute("aria-expanded", false);
      el.blur();
      parent.classList.remove("is--open");
      title.classList.remove("is--open");
    }, 500);
  } else {
    content.style.height = "0px";
    content.addEventListener(
      "transitionend",
      function () {
        el.setAttribute("aria-expanded", false);
        el.blur();
        parent.classList.remove("is--open");
        title.classList.remove("is--open");
      },
      {
        once: true,
      }
    );
  }
}

if (accordion.length > 0) {
  accordionButton.forEach(function (el) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      toggleAccordionItem(el);
    });
  });

  window.addEventListener("hashchange", locationHashChanged);

  // check if uri has hash
  if (hash != "") {
    const el = document.getElementById(hash.substring(1));
    if (el && el.classList.contains("js-accordion__button")) {
      toggleAccordionItem(el);
    }
  }
}
