import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

/**
 * Teaser401
 * Displays cards in a slider
 */
class Teaser401 {
  /**
   * constructor for Teaser401
   *
   * @param   {HTMLElement}  wrapperElement Outer Element of Component
   *
   * @return  {ClassInstance}
   */
  constructor(wrapperElement) {
    this.s = {
      slider: ".js-teaser401__slider",
      slide: ".js-teaser401__slide",
      pagination: ".js-teaser401__pagination",
    };
    this.e = {
      wrapper: wrapperElement,
      slider: wrapperElement.querySelector(this.s.slider),
      slides: wrapperElement.querySelectorAll(this.s.slide),
      pagination: wrapperElement.querySelector(this.s.pagination),
    };

    this.swiper;

    if (this.e.wrapper && this.e.slider) {
      this.init();
    }

    return this;
  }

  init() {
    this.initSwiper();
  }

  /**
   * Init the swiper instance
   */
  initSwiper() {
    this.swiper = new Swiper(this.e.slider, {
      slidesPerView: "auto",
      spaceBetween: 24,
      centeredSlides: true,
      centerInsufficientSlides: false,
      pagination: {
        clickable: true,
        el: this.e.pagination,
        type: "bullets",
      },
      breakpoints: {
        760: {
          slidesPerView: "auto",
          centeredSlides: true,
          centerInsufficientSlides: false,
        },
        1024: {
          centeredSlides: false,
          centerInsufficientSlides: true,
          slidesPerView: 4,
        },
        1400: {
          centeredSlides: false,
          centerInsufficientSlides: true,
          slidesPerView: 5,
        },
        1600: {
          centeredSlides: false,
          centerInsufficientSlides: true,
          slidesPerView: "auto",
        },
      },
    });
  }
}

export default () => {
  const teaserElements = document.querySelectorAll(".js-teaser401");
  teaserElements.forEach(function (element) {
    new Teaser401(element);
  });
};
