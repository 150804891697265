const updatePagination = (element) => {
  const page = parseInt(element.dataset.page);
  const itemsPerPage = parseInt(element.dataset.itemsPerPage);
  const visibleItems = page * itemsPerPage;
  const items = element.querySelectorAll(
    ".news101-item:not(.news101-item--hidden-by-tag):not(.news101-item--hidden-by-type)"
  );

  items.forEach((item, index) => {
    item.classList.remove("news101-item--hidden-by-pagination");
    if (index >= visibleItems) {
      item.classList.add("news101-item--hidden-by-pagination");
    }
  });

  element.querySelector(".news101--list__load-more")?.classList.remove("news101--list__load-more--hidden");
  if (items.length <= visibleItems) {
    element.querySelector(".news101--list__load-more")?.classList.add("news101--list__load-more--hidden");
  }
};

const updateTagFilter = (element, selectedTag) => {
  const items = element.querySelectorAll(".news101-item");

  if (items.length) {
    items.forEach((item) => {
      item.classList.remove("news101-item--hidden-by-tag");
      if (item.dataset.tag != selectedTag && selectedTag != "") {
        item.classList.add("news101-item--hidden-by-tag");
      }
    });
  }

  element.dataset.page = 1;
  updatePagination(element);
};

const updateTypeFilter = (element, selectedTypes) => {
  const items = element.querySelectorAll(".news101-item");

  items.forEach((item) => {
    item.classList.remove("news101-item--hidden-by-type");
    if (selectedTypes.length > 0 && !selectedTypes.includes(item.dataset.type)) {
      item.classList.add("news101-item--hidden-by-type");
    }
  });

  element.dataset.page = 1;
  updatePagination(element);
};

document.querySelectorAll(".news101-filterlist").forEach((element) => {
  // click "load  more" button
  element.querySelectorAll(".news101--list__load-more a").forEach((link) => {
    link.addEventListener("click", () => {
      element.dataset.page = parseInt(element.dataset.page) + 1;
      updatePagination(element);
    });
  });

  // change "tag" filter
  element.querySelectorAll(".news101__filters__tag").forEach((tag) => {
    tag.addEventListener("change", (e) => {
      updateTagFilter(element, e.target.value);
    });
  });

  // change "type" filter
  element.querySelectorAll(".news101__filters__type input").forEach((filter) => {
    filter.addEventListener("change", () => {
      let filters = [];
      element.querySelectorAll(".news101__filters__type input").forEach((checkbox) => {
        if (checkbox.checked) filters.push(checkbox.value);
      });
      updateTypeFilter(element, filters);
    });
  });
});
