import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

/**
 * news101-s-s
 * Displays cards in a slider
 */

class News101_Slider_Subpage {
  /**
   * constructor for News101_Slider_Subpage
   *
   * @param   {HTMLElement}  wrapperElement Outer Element of Component
   *
   * @return  {ClassInstance}
   */
  constructor(wrapperElement) {
    this.s = {
      slider: ".js-news101-s-s__slider",
      slide: ".js-news101-s-s__slide",
      pagination: ".js-news101-s-s__pagination",
    };
    this.e = {
      wrapper: wrapperElement,
      slider: wrapperElement.querySelector(this.s.slider),
      slides: wrapperElement.querySelectorAll(this.s.slide),
      pagination: wrapperElement.querySelector(this.s.pagination),
    };

    this.swiper;

    if (this.e.wrapper && this.e.slider) {
      this.init();
    }

    return this;
  }

  init() {
    this.initSwiper();
    this.initBreakpoints();
  }

  /**
   * Init the swiper instance
   */
  initSwiper() {
    this.swiper = new Swiper(this.e.slider, {
      slidesPerView: 1.4,
      spaceBetween: 24,
      centeredSlides: true,
      centerInsufficientSlides: false,
      pagination: {
        clickable: true,
        el: this.e.pagination,
        type: "bullets",
        enabled: true,
      },

      breakpoints: {
        1: {
          slidesPerView: 1.4,
          centerInsufficientSlides: true,
          centeredSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        400: {
          slidesPerView: 1.6,
          centeredSlides: true,
          centerInsufficientSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        600: {
          slidesPerView: 2.2,
          centeredSlides: true,
          centerInsufficientSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        800: {
          slidesPerView: 2.4,
          centeredSlides: true,
          centerInsufficientSlides: true,
          pagination: {
            clickable: true,
            el: this.e.pagination,
            type: "bullets",
            enabled: true,
          },
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
          centerInsufficientSlides: false,
          pagination: {
            clickable: false,
            el: this.e.pagination,
            type: "bullets",
            enabled: false,
          },
        },
      },
      on: {
        afterInit: (swiperInstance) => {
          if (swiperInstance.slides.length >= 2) {
            if (swiperInstance.currentBreakpoint <= 800) {
              const targetSection = this.e.wrapper.closest("section");
              targetSection.classList.add("ml0");
              targetSection.classList.add("mr0");
            }
          }
        },
      },
    });
  }

  initBreakpoints() {
    const _slider = this.e.slider;
    this.swiper.on("breakpoint", function () {
      const targetSection = _slider.closest("section");
      if (this.currentBreakpoint >= 1024) {
        targetSection.classList.remove("ml0");
        targetSection.classList.remove("mr0");
      } else {
        targetSection.classList.add("ml0");
        targetSection.classList.add("mr0");
      }
      this.update();
    });
  }
}

const newsElements = document.querySelectorAll(".js-news101-s-s");
newsElements.forEach(function (element) {
  new News101_Slider_Subpage(element);
});
