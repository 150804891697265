import { $All } from "Packages/Bahn/DB.Neos.Base/Resources/Private/Assets/Scripts/Modules/Helper";
//
// Map Overlay Marker Hover
//
const $imageHiglighterText = $All(".js-image-highlighter__text");
const $imageHiglighterMarker = $All(".js-image-highlighter__marker");

$imageHiglighterText.forEach(function (el, i) {
  const hoverImage = document.getElementById(el.getAttribute("data-overlay"));

  el.addEventListener("mouseenter", function (e) {
    const number = el.getAttribute("data-number");
    $imageHiglighterMarker[i].classList.add("hover");

    if (hoverImage !== null) {
      hoverImage.classList.add("active");
    }
  });

  el.addEventListener("mouseleave", function (e) {
    $imageHiglighterMarker[i].classList.remove("hover");

    if (hoverImage !== null) {
      hoverImage.classList.remove("active");
    }
  });
});

$imageHiglighterMarker.forEach(function (el, i) {
  const hoverImage = document.getElementById(el.getAttribute("data-overlay"));

  el.addEventListener("mouseenter", function (e) {
    $imageHiglighterText[i].classList.add("hover");

    if (hoverImage !== null) {
      hoverImage.classList.add("active");
    }
  });

  el.addEventListener("mouseleave", function (e) {
    $imageHiglighterText[i].classList.remove("hover");

    if (hoverImage !== null) {
      hoverImage.classList.remove("active");
    }
  });
});
