/**
 * Image blend slider which lets the user compare to images by overlaying them and sliding the visible area of the top image
 */
 class ImageBlend {
	/**
	 * constructor for image blend element
	 *
	 * @param   {node}  wrapperElement	wrapper for whole element
	 *
	 * @return  {ClassInstance}
	 */
	constructor(wrapperElement) {

		this.s = {
			mediaContainer: '.js-image-blend__media',
			imageContainer: '.js-image-blend__movable-image', // containing div of wwhich will be moved. img inside will be cut off
			indicator: '.js-image-blend__indicator',
			button: '.js-image-blend__button',
		};
		this.e = {
			wrapper: wrapperElement,
			mediaContainer: wrapperElement.querySelector(this.s.mediaContainer),
			imageContainer: wrapperElement.querySelector(this.s.imageContainer),
			indicator: wrapperElement.querySelector(this.s.indicator),
			button: wrapperElement.querySelector(this.s.button),
		};

		if (this.e.mediaContainer && this.e.imageContainer && this.e.button) {
			this.init();
		}
		return this;

	}

	init() {
		this.e.button.addEventListener('pointerdown', onPressed, {passive: true});
		window.addEventListener('resize', () => {
			calculateDimensions();
			let lastPointerX = window.innerWidth / 2;
			update(lastPointerX);
		});
		let self = this;

		let lastPointerX = window.innerWidth / 2; // used for calculations on resize; default value center
		let mediaRect, mediaCenter, buttonTranslateMax, imageRight, buttonTranslate, raf;
		let multiply = 1;

		calculateDimensions();

		function calculateDimensions(){
			mediaRect = self.e.mediaContainer.getBoundingClientRect();
			mediaCenter = mediaRect.right - (mediaRect.width / 2);
			buttonTranslateMax = mediaRect.width / 2;
		}

		function onPressed(event) {
			multiply = document.body.classList.contains('rotate180') ? -1 : 1;
			document.body.addEventListener('pointermove', onMove, {passive: true});
			document.body.addEventListener('touchmove', onMove, {passive: true});
			document.body.addEventListener('pointerup', onRelease, {passive: true});
			document.body.addEventListener('touchend', onRelease, {passive: true});

		}

		// simple clamp function to prevent values out of bounds
		function clamp(num, min, max){
			return num <= min ? min : num >= max ? max : num;
		}

		/**
		 * calculate the translate-value for our indicator / button andalso the position-right value for the image-container
		 * based on pointer position
		 *
		 * @param   {number}  pointerX  Pointer x-position
		 *
		 * @return  {array}            [buttonTranslate, imageCOntainerRight]
		 */
		function getValues(pointerX){
			let imageRight;
			if (multiply < 0) {
				imageRight = clamp( ((pointerX - mediaRect.left) * 100 / mediaRect.width) , 0, 100);
			} else {
				imageRight = clamp( ((mediaRect.right - pointerX) * 100 / mediaRect.width) , 0, 100); // image-container positionl-right value
			}
			return [
				clamp(pointerX - mediaCenter, -buttonTranslateMax, buttonTranslateMax) * multiply, // button translate
				imageRight,
			];
		}

		function onMove(event) {
			let xVal = event.clientX || event.pageX || event.touches[0].pageX;
			update(xVal);
		}

		function update(pointerX) {
			if (!raf) {
				lastPointerX = pointerX;
				let transformValues = getValues(pointerX);
				buttonTranslate = transformValues[0];
				imageRight = transformValues[1];
				raf = requestAnimationFrame(onMoveRaf);
			}
		}

		function onMoveRaf() {
			self.e.indicator.style.transform = 'translateX(' + buttonTranslate +  'px)';
			self.e.imageContainer.style.right = imageRight + '%';
			raf = null;
		}

		function onRelease(event) {
			document.body.removeEventListener('pointermove', onMove);
			document.body.removeEventListener('touchmove', onMove);
			document.body.removeEventListener('pointerup', onRelease);
			document.body.removeEventListener('touchend', onRelease);

			// stop animation frame when user iteraction already stopped
			if (raf) {
			  cancelAnimationFrame(raf);
			  raf = null;
			}
		}
	}
}

function initImageBlend() {
	const s = {
		element: '.js-image-blend'
	};
	const imageBlendElements = document.querySelectorAll(s.element);
	imageBlendElements.forEach(function (element) {
		new ImageBlend(element);
	});
}

initImageBlend();
