const data = {};

export default function useFetchJson(query) {
	return new Promise(resolve => {
		if (typeof data[query] === 'undefined') {
			data[query] = fetch(query).then((response) => response.json());
		}

		resolve(data[query]);
	});
}
