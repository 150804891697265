function $(selector, context) {
  return (context || document).querySelector(selector);
}

function $All(selector, context) {
  var arr = [];
  arr.push.apply(arr, (context || document).querySelectorAll(selector));
  return arr;
}

var mainMenu = document.querySelector(".js-menu");
var mainMenuToggleLinks = $All(".js-toggle-menu__item > .js-disabled-link");
var mainMenuDisabledLinks = $All(".js-disabled-link");
var mainMenuToggleInput = document.querySelector(".js-showmenu__input");

//
// Open Desktop Overlay Menu Items
//
mainMenuToggleLinks.forEach(function (el) {
  el.addEventListener("click", function (event) {
    event.preventDefault();

    var openItem = document.querySelector(".js-toggle-menu__listitem.is-open");

    if (el.parentNode.classList.contains("is-open")) {
      el.parentNode.classList.remove("is-open");
    } else {
      if (openItem !== null) {
        openItem.classList.remove("is-open");
      }

      // close all other open Menus
      mainMenuToggleLinks.forEach(function (openElements) {
        openElements.parentNode.classList.remove("is-open");
      });

      el.parentNode.classList.add("is-open");
    }
  });
});

//
// Detect Click outside of Overlay Menu
//
document.addEventListener("click", function (event) {
  if (event.target.closest(".js-toggle-menu__item, .submenu")) return;

  var openItem = document.querySelector(".js-toggle-menu__item.is-open");
  if (openItem !== null) {
    openItem.classList.remove("is-open");
  }
});

//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener("keyup", function (event) {
  var key = event.which || event.keyCode;
  if (key === 27) {
    var openItem = document.querySelector(".js-toggle-menu__item.is-open");
    if (openItem !== null) {
      openItem.classList.remove("is-open");
    }
  }
});

//
// Close Mobile Menu on window.resize
//
window.addEventListener("resize", function () {
  if (mainMenuToggleInput !== null && mainMenuToggleInput.checked === true) {
    mainMenuToggleInput.checked = false;
  }
});

//
// Open Desktop Overlay Menu Items
//
mainMenuDisabledLinks.forEach(function (link) {
  link.addEventListener("click", function (event) {
    event.preventDefault();
  });
});

//menu active state
[...document.querySelectorAll("a")]
  .filter((link) => link.href === location.href)
  .forEach((link) => link.classList.add("active"));
