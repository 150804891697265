export function initLayer() {
  const layerButton = document.querySelector("#layer-button");
  const layerMenu = document.querySelector("#layer-menu");

  if (layerButton == null) {
    return;
  }

  layerButton.addEventListener("click", function () {
    layerMenu.classList.toggle("open");
  });
}
